<template>
  <div class="row">
    <div class="col-6">


      <div v-if="showtab">
        <div class="tab-box">
          <ul class="tab-head nav nav-tabs">
            <li
              class="nav-item"
              data-id="tc1"
              @click="switch_tab('map')"
              v-bind:class="[tab_index=='map' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang">{{$language('google_map')}}</div>
              </a>
            </li>

            <li
              class="nav-item"
              data-id="tc6"
              @click="switch_tab('vr')"
              v-bind:class="[tab_index=='vr' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang">{{$language('vr')}}</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="tab_index == 'map'">
        <div class="page-title-box">
          <h4 class="page-title">{{$language('google_map')}}</h4>
        </div>
        <div class="card-box mb-2">
          <!-- map -->
          <div id="googleMap" class="google"></div>
        </div>
      </div>

      <div v-if="tab_index == 'vr'">
        <div class="page-title-box">
          <h4 class="page-title">{{$language('vr')}}</h4>
        </div>
        <div class="card-box mb-2">
          <!-- map -->
          <div id="googleMap" class="google">
            <iframe :src="vrurl"></iframe>
          </div>
        </div>
      </div>

    </div>
    <div class="col-6">
      <div class="page-title-box">
        <h4 class="page-title">{{$language('google_street_view')}}</h4>
      </div>
      <div class="card-box mb-2">
        <!-- streetview -->
        <div id="googlePano" class="google"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: {
    address: String,
    reload: Number,
    showtab:{
      type:Boolean,
      default:false
    },
    vrurl:{
      type:String,
      default:'https://my.matterport.com/show/?m=1Jtw4JmDjAr'
    }
  },
  data() {
    return {
      center: { lat: Number, lng: Number },
      tab_index:'map',
      google: {
        maps: {
          StreetViewPanorama: {},
          Map: {},
          Marker: {},
          InfoWindow: {},
          MapTypeId: {},
        },
      },
    };
  },
  mounted() {
    this.loadGoogleMapsScript().then(() => {
      this.google = window.google;
      this.initializeMap();
    });
  },
  methods: {
    switch_tab(index){
      this.tab_index = index;
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=geometry";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 5000);
      });
    },
    initializeMap() {
      const mapContainer = this.$el.querySelector("#googleMap");
      const panoContainer = this.$el.querySelector("#googlePano");
      const { Map, Marker, InfoWindow, MapTypeId } = this.google.maps;
      const Geocoder = new this.google.maps.Geocoder();

      // map create
      let map = new Map(mapContainer, {
        zoom: 16,
        mapTypeId: MapTypeId.ROADMAP,
      });

      var _that = this;
      new Geocoder.geocode({ address: _that.address }, function (
        results,
        status
      ) {
        if (status === "OK" && results[0]) {
          let locate = results[0].geometry.location;
          _that.center = {
            lat: Number(locate.lat()),
            lng: Number(locate.lng()),
          };

          // set locate
          map.setCenter(_that.center);
          var infowindow = [];
          var markers = [];
          infowindow[0] = new InfoWindow({
            content:
              '<div id="content">' +
              '<h3 id="firstHeading" class="firstHeading">' +
              _that.address +
              "</h3>" +
              "</div>",
          });

          markers[0] = new Marker({
            position: {
              lat: Number(locate.lat()),
              lng: Number(locate.lng()),
            },
            map: map,
            title: _that.address,
          });
          markers[0].addListener("click", () => {
            infowindow[0].open({
              anchor: markers[0],
              map: map,
              shouldFocus: false,
            });
          });

          // set locate
          map.setCenter(_that.center);
          _that.initialize(panoContainer, _that.center);
        } else {
          console.log("failed load google api  : " + status);
          return;
        }
      });
    },
    initialize(panoContainer, center) {
      var svService = new this.google.maps.StreetViewService();
      var panoRequest = {
        location: new this.google.maps.LatLng(center.lat, center.lng),
        preference: this.google.maps.StreetViewPreference.NEAREST,
        radius: 50,
        source: this.google.maps.StreetViewSource.OUTDOOR,
      };
      var _that = this;
      svService.getPanorama(panoRequest, function (panoData, status) {
        if (status === _that.google.maps.StreetViewStatus.OK) {
          new _that.google.maps.StreetViewPanorama(panoContainer, {
            pano: panoData.location.pano,
            pov: {
              heading: 10,
              pitch: 10,
            },
          });
        } else {
          //Handle other statuses here
        }
      });
    },
  },
  watch: {
    reload: function () {
      this.initializeMap();
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.google {
  height: 600px;
  iframe{
    width: 100%;
    height: 100%;
  }
}
</style>
